import React from "react";
import logo from "../asset/img/2.png";
import logo1 from "../asset/img/1.png"
import {FaTwitter} from "react-icons/fa"

function Home() {

    const winners = ["@vaktinvarsa", "@milcokmutsuz", "@lovevpcouple", "@anlatmayacagim",
        "@bbosports", "@bbosportss", "@Sporzen",
        "@lanbiisus", "@guzelligebakin", "@artcullture", "@akillanmamben","@missbutterfly17"]

    return (
        <div className="bacground_img container col-sm-10 " style={{height: `${window.innerWidth>1024? Number((window.innerHeight)):1250}px`}}>

            <div className=" bacground_1">
                <div className="d-flex justify-content-center col-sm-5">
                    <img className="img1" src={logo} alt="" />
                </div>
                <div className="d-flex justify-content-center col-sm-5">
                    <h3>FashionTV Token Kazananlar</h3>
                </div>
                <div className="col-sm-5 p-2">
                    <p className="">19 Ocak 2023 Tarihinde @ftvttoken Twitter hesabımızda anons edilen, FashionTV Token Kazananlar Kulübü RT kampanyasında 5’er FashionTV Token kazanan 12 Twitter hesabının listesi aşağıdadır.</p>
                    <p className="">
                        Katılımınız için teşekkür ederiz. Sosyal Medya hesaplarımızı takip ederek kampanyalarımızı herkesten önce öğrenebilir, kazanma şansınızı arttırabilirsiniz.
                    </p>
                </div>
                <div className="col-sm-8 p-3 row">
                  {winners.map(res=>{
                    return(
                        <div className="col-sm-6 p-1">
                            <ul className="list-group">
                                <li className="list-group-item">< FaTwitter className="fa"/>{res}</li>
                            </ul>
                            
                            
                        </div>
                    )
                  })}
                </div>
                <div className="p-2">
                    <h5>Ödül teslim şartları:</h5>
                    <p> İlgili hesap sahipleri ödüllerini alabilmek için ICRYPEX’te ücretsiz olarak hesap oluşturarak veya sahip oldukları hesap varsa bu hesabı ait Müşteri Numaralarını ilgili Twett’i RT yaparak kampanyaya katıldıkları Twitter hesabından @ftvttoken Twitter hesabına DM yolu ile iletmeleri gerekmektedir. Ödülün Kripto Borsasından çekimi KYK ve diğer borsa içi kurallara tabidir.</p>
                   <img className="img2" src={logo1} alt="" />
                </div>

            </div>

        </div>
    )
}

export default Home